import React from "react";
import OperationsContainer from "./OperationsContainer";
import OperationsTable from "./OperationsTable";
import "./Operations.css";

export const Operations = () => {
  return (
    <OperationsContainer resourceName="operations">
      <OperationsTable />
    </OperationsContainer>
  );
};
