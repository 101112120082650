import React from "react";

const OperationsTable = ({ operations }) => {
  return (
    <div className="table-container">
      <table className="styled-table">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Symbol</th>
            <th>Type</th>
            <th>Profit</th>
          </tr>
        </thead>
        <tbody>
          {operations?.map((order, i) => (
            <tr key={`${order.orderId}-${i}`}>
              <td>{order.orderId}</td>
              <td>{order.symbol}</td>
              <td>{order.type.replace("POSITION_TYPE_", "")}</td>
              <td
                className={
                  order.calculatedProfit >= 0 ? "positive" : "negative"
                }
              >
                {order.calculatedProfit.toFixed(4)}%
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OperationsTable;
