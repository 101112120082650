import { useEffect, useState } from "react";
import { io } from "socket.io-client";

const SOCKET_URL = "wss://metainfo.milktech.io"; // URL del WebSocket

export const useProfitSocket = () => {
  const [socket, setSocket] = useState(null);
  const [profitOperation, setProfitOperation] = useState(null);
  const [positions, setPositions] = useState([]);

  const token = localStorage.getItem("token");

  useEffect(() => {
    const newSocket = io(SOCKET_URL, {
      transports: ["websocket"], // Forzar uso de WebSocket
      auth: { token },
    });

    newSocket.on("connect", () => {
      console.log("✅ Conectado al WebSocket");
    });

    newSocket.on("disconnect", () => {
      console.log("❌ Desconectado del WebSocket");
    });

    newSocket.on("trading.operation", (data) => {
      console.log("💰 Evento trading.operation:", data);
      setProfitOperation(data);
    });

    newSocket.on("position", (data) => {
      setPositions((prev) => [...prev, data]);
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  return { socket, profitOperation, positions };
};
