import React, { useState, useEffect } from "react";
import { useProfitSocket } from "../../../utils/hooks/useProfitSocket";

const OperationsContainer = (props) => {
  const [socketOps, setSocketOps] = useState([]);

  const { children, resourceName } = props;
  const { positions } = useProfitSocket();

  useEffect(() => {
    if (positions && positions.length > 0) {
      setSocketOps((prev) => {
        const updatedOrders = [...prev];

        positions.forEach((newData) => {
          const index = updatedOrders.findIndex(
            (o) => o.orderId === newData.orderId
          );

          if (index !== -1) {
            // Actualiza la fila si el orderId ya existe
            updatedOrders.splice(index, 1, newData);
          } else {
            // Agrega una nueva fila si el orderId no existe
            updatedOrders.push(newData);
          }
        });

        return updatedOrders;
      });
    }
  }, [positions]);

  return (
    <div>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { [resourceName]: socketOps });
        }
      })}
    </div>
  );
};

export default OperationsContainer;
